import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <h1>Not Found</h1>
        <p>
          The requested URL /markenhistorie/markenhistorie/ was not found on
          this server.
        </p>
      </div>
    );
  }
}

export default Page;
